<template>
      <div>
          <div v-html="template" id="dynamicText"></div>
      </div>
</template>
<script>
    export default {
        name: 'DynamicText',
        data()  {
          return {
              template: `
                  
<p>Based on the above settings the cost per shower is <span class="totalCostPerShower">ddd</span>. With a shower timer the cost per shower is reduced to <span class="totalCostPerShowerReduced">ddd</span>.  </p>



<p>The water cost per shower is <span class="costWaterPerShower">ddd</span> but with a shower timer the water per shower costs <span class="costWaterPerShowerReduced">ddd</span>.</p>



<p>The cost of <span class="energyType">ddd</span> per shower is <span class="costHeatingPerShower">ddd</span> but with a shower timer you pay <span class="costHeatingPerShowerReduced">ddd</span> for <span class="energyType">ddd</span>.</p>



<p>The total cost of showers per annum without a shower timer is <span class="annualCost">ddd</span> but with a shower timer the cost is reduced to <span class="annualCostReduced">ddd</span> which is a  saving of <span class="annualSaving">ddd</span>.</p>

  `
          }
        }
    }

</script>

<style scoped>

#dynamicText {
    text-align: left;
}

</style>
