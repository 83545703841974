import Vue from 'vue'
import App from './App.vue'
import '@/assets/css/tailwind.css'
import Vue2Filters from 'vue2-filters'

Vue.config.productionTip = false;
Vue.use(Vue2Filters);
new Vue({
  render: h => h(App),
}).$mount('#app')
