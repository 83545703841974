export default class ShowerCost {

    // gas:

    // gas_cost * litres_per_minute * average_shower_time * (40-cold_water_temp)*4.2/600

    /*
     * https://power-calculation.com/hot-water-heater-cost-energy-consumption.php
A simple formula to estimate the energy required to heat a volume of water is :
E = C*V*DeltaT/PR
Where E = energy in kWh
C = Specific heat of water - 4.187 kJ/kgK, or 1,163 Wh/kg°C
V = volume of water to heat
deltaT = Th-Tc
Th = temperature of hot water
Tc = temperature of input cold water
PR = performance ratio (it includes losses of heat through pipes and tank), default value = 0.9
*
* -------------------------------------------------------------------------
* https://sciencing.com/calculate-time-heat-water-8028611.html
*
* Calculate Kilowatt-Hours

Calculate the kilowatt-hours (kWh) required to heat the water using the following formula: Pt = (4.2 × L × T ) ÷ 3600. Pt is the power used to heat the water, in kWh. L is the number of liters of water that is being heated and T is the difference in temperature from what you started with, listed in degrees Celsius.
Solve for Thermal Power

Substitute in the appropriate numbers into the equation. So imagine you are heating 20 liters of water from 20 degrees to 100 degrees. Your formula would then look like this: Pt = (4.2 × 20 × (100-20)) ÷ 3600, or Pt = 1.867

* */
    constructor() {

        this.warmWaterTemp = 40;
        this.multiplierElectricity = 4.2/2800;
        this.multiplierGas = 4.2/600;


        this.data = {
            showerFlowRate: 12,
            coldWaterTemp: 12,
            warmWaterTemp: 40,
            averageShowerTime: 12,
            numberShowersPerDay: 5,
            waterCost: 300,
            costHeatingSourceE: 35,
            costHeatingSourceG: 3.5,
            multiplierGas: 4.2/600,
            multiplierElectricity: 4.2/2800,
            energyType: "electricity"
        };

    /*
        const result = {
            costWaterPerShower: this.costWaterPerShower(data),
            costHeatingPerShower: this.costHeatingPerShower(data),
            totalCostPerShowerthis: this.totalCostPerShower(data),
            annualCost: this.annualCost(data)
        };

        for (let i in result) {
            console.log(`${i}: $${result[i].toFixed(2)}`);
        } */
    }

    setData(data) {
        console.log('set data: ' , data);
        this.data = data;
    }



    costWaterPerShower(data) {
        return (data.showerFlowRate * data.averageShowerTime * data.waterCost)/1000;
    }

    costHeatingPerShower(data) {
        // returns cents

        const delta = data.warmWaterTemp - parseInt(data.coldWaterTemp);

        const litres = data.showerFlowRate * data.averageShowerTime;
        let multiplier = data.multiplierElectricity;
        let costHeatingSource = data.costHeatingSourceE;
        if (data.energyType == 'gas') {
            multiplier = data.multiplierGas;
            costHeatingSource = data.costHeatingSourceG;
        }

        const result = costHeatingSource * litres * delta * multiplier;

        return result;
    }

    costHeatingPerAnnum(data) {
        return this.costHeatingPerShower(data) * data.numberShowersPerDay * data.numberCubicles * 365;
    }

    costWaterPerAnnum(data) {
        return this.costWaterPerShower(data) * data.numberShowersPerDay * data.numberCubicles * 365;
    }

    totalCostPerShower(data) {
        return (this.costWaterPerShower(data) + this.costHeatingPerShower(data));
    }

    totalCostPerDay(data) {
        return this.totalCostPerShower(data) * data.numberShowersPerDay;
    }

    cubicleCostPerAnnum() {

    }

    annualCost(data) {

      //  return (this.totalCostPerShower(data) * data.numberShowersPerDay * 365);
        return this.costHeatingPerAnnum(data) + this.costWaterPerAnnum(data);
    }

    getSaving(data) {

        let savings = {};

        if(! data.withShowerTimer) {
           // return {};
        }

        const dataCloned = Object.assign({}, data);

        dataCloned.averageShowerTime = data.showertimerDuration;

        savings.costWaterPerShower =    this.costWaterPerShower(dataCloned);
        savings.costHeatingPerShower =  this.costHeatingPerShower(dataCloned);
        savings.costWaterPerAnnum =     this.costWaterPerAnnum(dataCloned);
        savings.costHeatingPerAnnum =   this.costHeatingPerAnnum(dataCloned);
        savings.totalCostPerShower =    this.totalCostPerShower(dataCloned);
        savings.totalCostPerDay =       this.totalCostPerDay(dataCloned);
        savings.annualCost =            this.annualCost(dataCloned);
        savings.saving = (this.annualCost(data) - savings.annualCost);

        return (savings);
    }

    getData() {
        return this.data;
    }

    getQueryParams(data) {
        const params = new URLSearchParams();


        for(let i in data) {
            if(i == 'urlCopied' || i == 'dynamicText' || i == 'dynamicTextEls' || i == 'energyHelp' || i == 'withShowerTimer') {
              continue;
            }

            params.append(i, data[i]);
        }

        return encodeURI(params.toString());
    }
}
