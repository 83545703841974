<template>
    <div class="explainer-text-container mx-auto mt-8 text-left">

<p>Based on the above settings the cost per shower is {{ totalCostPerShower | dollarAmount }}. With a shower timer the cost per shower is reduced to {{ totalCostPerShowerReduced | dollarAmount }}. </p>



<p>The water cost per shower is {{ costWaterPerShower | dollarAmount }} but with a shower timer the water per shower costs {{ costWaterPerShowerReduced | dollarAmount }}.</p>



<p>The cost of {{ energyType }} per shower is {{ costHeatingPerShower | dollarAmount }} but with a shower timer you pay {{ costHeatingPerShowerReduced | dollarAmount }} for {{ energyType }}.</p>



<p>The total cost of showers per annum without a shower timer is {{ annualCost | dollarAmount }} but with a shower timer the cost is reduced to {{ annualCostReduced | dollarAmount }} which is a  saving of {{ annualSaving | dollarAmount }}.</p>

    </div>
</template>
<script>
    export default {
    name: 'ExplainerText',
    props: {

        annualCost: Number,
        annualCostReduced: Number,
        annualSaving: Number,
        withShowerTimer: Boolean,
        totalCostPerShower: Number,
        totalCostPerShowerReduced: Number,
        costWaterPerShower: Number,
        costWaterPerShowerReduced: Number,
        costHeatingPerShower: Number,
        costHeatingPerShowerReduced: Number,
        energyType: String
    },
    filters: {
        dollarAmount: function(val) {
          let money = (val/100).toLocaleString('en-AU',{
              style: 'currency',
              currency: 'AUD'
          });

          if(parseInt(val/100) < 1000) {
              return money;
          }

          let wholeDollars = Math.round(val/100);
          return `$${wholeDollars}`;
        }
    },
  }

</script>

<style scoped>


</style>
