<template>
    <div class="showercost-container mx-auto mt-16">
      <div>
</div>
         <div class="flex flex-end items-center">

             <p class="slider-box-p text-sm">
                Premises Type?
             </p>

             <div class="pl-2">

                <div class="flex items-center">
                     <input type="radio" id="residential" value="residential" v-model="premisesType" @click="forceRerender">
                     <label class="pl-1 w-24 inline-block text-left" for="residential">Residential</label>
                </div>

                <div class="flex items-center">
                    <input type="radio" id="commercial" value="commercial" v-model="premisesType">
                    <label class="pl-1 w-24 inline-block text-left" for="commercial">Commercial</label>
                </div>

            </div>

         </div>

        <div class="heater-type my-4 flex items-center">
            <p class="slider-box-p text-sm">Water Heater Type?</p>
            <div  class="w-32 text-left pl-4">
                <select v-model="energyType">
                    <option value="electricity">Electric</option>
                    <option value="gas">Gas</option>
                </select>
            </div>
        </div>

        <div class="slider-box"  v-if="energyType == 'electricity'">
            <p class="slider-box-p pr-1">Electricity Cost (cents per kWh): </p>
               <div class="slider-box-input">
                   <input size="3" v-model="costHeatingSourceE" type="number" min="20" max="50" step="0.01"  />
               </div>
       </div>

       <div class="slider-box" v-if="energyType == 'gas'">
            <p class="slider-box-p pr-1">Gas Cost (cents per MJoule): </p>
               <div class="slider-box-input">
                   <input size="3" v-model="costHeatingSourceG"  type="number" min="1.00" max="10.00" step="0.01"  />
               </div>
       </div>

        <div class="flex justify-end mt-2 mb-8" v-if="energyHelp">
          <div v-html="energyHelp" class="max-w-sm border border-gray-400 border-dotted p-1 leading-snug">
            hello
          </div>
        </div>



        <div class="slider-box">
             <p class="slider-box-p">Water Cost (cents per thousand litres): </p>
                 <range-slider class="slider" min="80" max="1000" step="1" v-model="waterCost"></range-slider>
            <div class="slider-box-input">
                <input v-model="waterCost" type="number" min="80" max="1000" step="1" />
            </div>
        </div>

        <div class="slider-box">
              <p class="slider-box-p">Shower Flow Rate (litres per minute): </p>
                 <range-slider class="slider" min="1" max="20" step="1" v-model="showerFlowRate"></range-slider>
             <div class="slider-box-input">
                <input size="3" v-model="showerFlowRate" type="number" min="1" max="20"  />
            </div>
        </div>

        <div class="slider-box">
             <p class="slider-box-p">Ambient Cold Water(&deg;C): </p>
                 <range-slider class="slider"  min="0" max="30" step="1" v-model="coldWaterTemp"></range-slider>
             <div class="slider-box-input">
                <input size="3" v-model="coldWaterTemp" type="number" min="0" max="30"  />
            </div>
        </div>

        <div class="slider-box">
             <p class="slider-box-p">Heated Water(&deg;C): </p>
                 <range-slider class="slider" min="40" max="45" step="1" v-model="warmWaterTemp"></range-slider>
             <div class="slider-box-input">
                <input size="3" v-model="warmWaterTemp" type="number" min="40" max="45"  />
            </div>
        </div>

          <div class="slider-box mt-8">
              <p class="slider-box-p">Number Showers Per <span v-if="premisesType=='commercial'">Cubicle Per</span> Day: </p>
                  <range-slider class="slider" min="1" max="50" step="1" v-model="numberShowersPerDay"></range-slider>
             <div class="slider-box-input">
                 <input v-model="numberShowersPerDay" type="number" min="1" max="50" />
             </div>
          </div>

        <div class="slider-box">
             <p class="slider-box-p">Average Shower Time (minutes): </p>
                 <range-slider class="slider" min="1" max="30" step="1" v-model="averageShowerTime"></range-slider>
            <div class="slider-box-input">
             <input size="3" v-model="averageShowerTime" type="number" min="1" max="30" />
            </div>
        </div>


        <div class="h-16">
          <div class="slider-box" v-if="premisesType=='commercial'">
              <p class="slider-box-p">Number of Cubicles</p>
                  <range-slider class="slider" min="1" max="50" step="1" v-model="numberCubicles"></range-slider>
             <div class="slider-box-input">
                 <input v-model="numberCubicles" type="number" min="1" max="50" />
             </div>
          </div>
        </div>

          <p class="my-0 flex justify-center items-center">
                <input class="mr-1" v-model="withShowerTimer" type="checkbox" @click="forceRerender" /> Enable Shower Timer
          </p>

          <div class="slider-box">
               <p class="slider-box-p">Shower Timer Duration (minutes): </p>
                   <range-slider class="slider" min="1" max="10" step="1" v-model="showertimerDuration"></range-slider>
              <div class="slider-box-input">
               <input size="3" v-model="showertimerDuration" type="number" min="1" max="10" />
              </div>
          </div>
        <table id="data-table" class="showercost-table w-full mt-4 mb-8">
             <tbody>
                 <tr class="text-white bg-blue-700">
                      <th class="w-1/6 p-2"></th>
                      <th class="font-light p-1 text-xs">Per Shower</th>
                      <th id="table-cell" class="font-light p-1 text-xs"><span v-if="premisesType=='residential'">Per Day</span><span v-if="premisesType=='commercial'">Per Day (Each Cubicle)</span></th>
                      <th v-if="premisesType=='commercial'" class="font-light p-1 text-xs">Total Per Day ({{this.numberCubicles}} {{this.numberCubicles | pluralize('Cubicle')}})</th>
                      <th class="font-light p-1 text-xs">Per Annum</th>
                 </tr>

                 <tr class="bg-gray-300">
                     <th class="p-2 text-xs">Cost of Water</th>
                     <td class="text-sm">
                        <span v-bind:class="{ reduced: withShowerTimer }">{{costWaterPerShower | dollarAmount}}</span>
                        <span v-if="withShowerTimer">{{costWaterPerShowerReduced | dollarAmount}}</span>
                     </td>
                     <td class="text-sm">
                        <span v-bind:class="{ reduced: withShowerTimer }">{{costWaterPerDay | dollarAmount}}</span>
                        <span v-if="withShowerTimer">{{costWaterPerDayReduced | dollarAmount}}</span>
                     </td>
                     <td v-if="premisesType=='commercial'" class="text-sm">
                       <span v-bind:class="{ reduced: withShowerTimer }">{{costWaterPerDayAllCubicles | dollarAmount}}</span>
                       <span v-if="withShowerTimer">{{costWaterPerDayAllCubiclesReduced | dollarAmount}}</span>
                     </td>
                     <td class="text-sm">
                        <span v-bind:class="{ reduced: withShowerTimer }">{{costWaterPerAnnum | dollarAmount}}</span>
                        <span v-if="withShowerTimer">{{costWaterPerAnnumReduced | dollarAmount}}</span>
                     </td>
                 </tr>

                 <tr class="bg-gray-300">
                     <th class="p-2 text-xs">Cost of Heating</th>
                     <td class="text-sm">
                        <span v-bind:class="{ reduced: withShowerTimer }">{{costHeatingPerShower | dollarAmount}}</span>
                        <span v-if="withShowerTimer">{{costHeatingPerShowerReduced | dollarAmount}}</span>
                     </td>

                     <td class="text-sm">
                        <span v-bind:class="{ reduced: withShowerTimer }">{{costHeatingPerDay | dollarAmount}}</span>
                        <span v-if="withShowerTimer">{{costHeatingPerDayReduced | dollarAmount}}</span>
                     </td>
                     <td v-if="premisesType=='commercial'" class="text-sm">
                         <span v-bind:class="{ reduced: withShowerTimer }">{{costHeatingPerDayAllCubicles | dollarAmount}}</span>
                         <span v-if="withShowerTimer">{{costHeatingPerDayAllCubiclesReduced | dollarAmount}}</span>
                     </td>
                     <td class="text-sm">
                        <span v-bind:class="{ reduced: withShowerTimer }">{{costHeatingPerAnnum | dollarAmount}}</span>
                        <span v-if="withShowerTimer">{{costHeatingPerAnnumReduced | dollarAmount}}</span>
                     </td>

                 </tr>

                 <tr class="bg-gray-300">
                     <th class="p-2 text-xs">Total Cost</th>
                     <td class="text-sm">
                        <span v-bind:class="{ reduced: withShowerTimer }">{{totalCostPerShower | dollarAmount}}</span>
                        <span v-if="withShowerTimer">{{totalCostPerShowerReduced | dollarAmount}}</span>
                    </td>
                    <td class="text-sm">
                       <span v-bind:class="{ reduced: withShowerTimer }">{{totalCostPerDay | dollarAmount}}</span>
                       <span v-if="withShowerTimer">{{totalCostPerDayReduced | dollarAmount}}</span>
                    </td>
                    <td v-if="premisesType=='commercial'" class="text-sm">
                         <span v-bind:class="{ reduced: withShowerTimer }">{{(totalCostPerDayAllCubicles) | dollarAmount}}</span>
                          <span v-if="withShowerTimer">{{totalCostPerDayReducedAllCublicles | dollarAmount}}</span>
                    </td>

                     <td class="text-sm">
                        <span v-bind:class="{ reduced: withShowerTimer }"> {{annualCost | dollarAmount}}</span>
                        <span v-if="withShowerTimer">{{annualCostReduced | dollarAmount}}</span>
                     </td>
                 </tr>

             </tbody>
        </table>


        <div v-html="dynamicText" id="dynamicText" ref="dyn"></div>


        <p class="mt-8 text-gray-600 text-lg">Save a link to share these settings: <button @click="aMethod" class="shadow text-sm bg-blue-600 rounded-lg text-white border-none leading-loose">Create Link</button></p>
        <p>&nbsp;
        <transition name="fade">
            <span v-if="urlCopied" class="text-green-400">Your link has been copied to your clipboard</span>
        </transition>
      </p>



    </div>
</template>
<script>
      import axios from "axios";
      import RangeSlider from 'vue-range-slider';
      import _ from 'lodash';
      import ShowerCost from '../inc/ShowerCost';
      import ExplainerText from './ExplainerText';
      import DynamicText from './DynamicText';
      import VRuntimeTemplate from "v-runtime-template";
      import 'vue-range-slider/dist/vue-range-slider.css';
      const showercost = new ShowerCost();
      const showerCostData = showercost.getData();

      export default {
          name: 'Calculator',
          props: {
            msg: String
          },
          components: {
            RangeSlider,
            DynamicText,
          },
          data()  {
            return {
          //    showerCostDataArray: showerCostDataArr,
              showerFlowRate: showerCostData.showerFlowRate,
              coldWaterTemp: showerCostData.coldWaterTemp,
              warmWaterTemp: showerCostData.warmWaterTemp,
              averageShowerTime: showerCostData.averageShowerTime,
              numberShowersPerDay: showerCostData.numberShowersPerDay,
              waterCost: showerCostData.waterCost,
              costHeatingSourceE: showerCostData.costHeatingSourceE,
              costHeatingSourceG:  showerCostData.costHeatingSourceG,
              showertimerDuration: showerCostData.showertimerDuration,
              numberCubicles: 1,
              multiplierGas: showerCostData.multiplierGas,
              multiplierElectricity: showerCostData.multiplierElectricity,
              energyType: 'electricity',
              premisesType: 'residential',
              withShowerTimer: false,
              urlCopied: false,
              dynamicText: '',
              energyHelp: '',
              dynamicTextEls: []
            }
          },
          methods: {
                aMethod: function() {
                    const query = showercost.getQueryParams(this.$data);
                    const link = `https://www.showertimer.com.au/savings-calculator/?${query}`;
                    const el = document.createElement('textarea');

                    el.value = link;
                    el.setAttribute('readonly', '');
                    el.style = {position: 'absolute', left: '-9999px'};
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);

                    this.urlCopied = true;
                    setTimeout(() => {
                      this.urlCopied = false;
                    }, 7000);
                },
                forceRerender() { // repaints the table after TDs are removed to workaround display issues
                    document.getElementById('data-table').style.opacity = 0;
                    setTimeout(function() {
                     document.getElementById('data-table').style.opacity = 1;
                   }, 10);

                },
                cacheDynamicTextEls() {
                    let dynamicTextElIds = [
                          'annualCost',
                          'annualCostReduced',
                          'annualSaving',
                      //    'withShowerTimer',
                          'totalCostPerShower',
                          'totalCostPerShowerReduced',
                          'costWaterPerShower',
                          'costWaterPerShowerReduced',
                          'costHeatingPerShower',
                          'costHeatingPerShowerReduced',
                          'energyType'
                        ];

                        const dynamicTextContainer = document.getElementById('dynamicText');


                        if(! dynamicTextContainer) {
                            console.log('div#dynamicText not found!');
                            return;
                        }

                        dynamicTextElIds.forEach( className => {
                            let els = dynamicTextContainer.querySelectorAll(`.${className}`);
                            console.log(`.${className}`,els);
                            if(els.length) {
                                this.dynamicTextEls[className] = els;
                            }
                        });
                },
                dynamicTextUpdate: _.throttle(function () {
                  console.log('change');
                    for(let i in this.dynamicTextEls) {
                        if(this.dynamicTextEls.hasOwnProperty(i)) {
                            let nodes = this.dynamicTextEls[i];
                            nodes.forEach( node => {
                                let txt = this[node.className];
                                txt = this.$options.filters.dollarAmount(txt);
                                if(! txt ) {
                                    return;
                                }
                                node.textContent = txt;
                            });
                        }
                    }
                }, 500)
              },
            watch: {
                annualCost: function() {
                    this.dynamicTextUpdate();
                },
                showertimerDuration: function() {
                    this.dynamicTextUpdate();
                }
            },
          computed: {
              showerTxt: function() {
                  return this.numberShowersPerDay > 1 ? 'showers' : 'shower';
              },

              annualCost: function() {
                //  this.dynamicText();
                  return showercost.annualCost(this.$data);
              },

              costWaterPerShower: function() {
                  return showercost.costWaterPerShower(this.$data);
              },

              costWaterPerDay: function() {
                  return showercost.costWaterPerShower(this.$data) * this.numberShowersPerDay;
              },

              costWaterPerDayAllCubicles: function() {
                return this.costWaterPerDay * this.numberCubicles;
              },

              costHeatingPerShower: function() {
                  return showercost.costHeatingPerShower(this.$data);
              },

              costHeatingPerDay: function() {
                  return this.costHeatingPerShower * this.numberShowersPerDay;
              },

              costHeatingPerDayAllCubicles: function() {
                  return this.costHeatingPerDay * this.numberCubicles;
              },

              totalCostPerShower: function() {
                // showercost.totalCostPerShower() returns cents
                  return showercost.totalCostPerShower(this.$data);
              },

              totalCostPerDay: function() {
                // showercost.totalCostPerShower() returns cents
                  return showercost.totalCostPerShower(this.$data) * this.numberShowersPerDay;
              },

              totalCostPerDayAllCubicles: function() {
                  return this.totalCostPerDay *  this.numberCubicles;
              },

              costWaterPerAnnum: function() {
                  return showercost.costWaterPerAnnum(this.$data);
              },

              costHeatingPerAnnum: function() {
                  return showercost.costHeatingPerAnnum(this.$data);
              },

            totalCostPerShowerReduced: function() {

                const savings = showercost.getSaving(this.$data);

                return savings.totalCostPerShower;
            },

            totalCostPerDayReduced() {
                return this.totalCostPerShowerReduced * this.numberShowersPerDay;
            },

            totalCostPerDayReducedAllCublicles: function() {
              return this.totalCostPerDayReduced * this.numberCubicles;
            },

            annualCostReduced: function() {
                const savings = showercost.getSaving(this.$data);

                return savings.annualCost;
            },

            annualSaving: function() {
                const savings = showercost.getSaving(this.$data);
                const annualCost = showercost.annualCost(this.$data);
                const annualSaving = annualCost - savings.annualCost;
                return savings.saving;
            },

            costWaterPerShowerReduced: function() {
                const savings = showercost.getSaving(this.$data);
                return savings.costWaterPerShower;
            },

            costWaterPerDayReduced: function() {
                const savings = showercost.getSaving(this.$data);
                return savings.costWaterPerShower * this.numberShowersPerDay;
            },

            costWaterPerDayAllCubiclesReduced: function() {
               return this.costWaterPerDayReduced * this.numberCubicles;
            },

            costHeatingPerShowerReduced: function() {
                const savings = showercost.getSaving(this.$data);
                return savings.costHeatingPerShower;
            },


            costHeatingPerDayReduced: function() {
              const savings = showercost.getSaving(this.$data);
              return savings.costHeatingPerShower * this.numberShowersPerDay;
            },

            costHeatingPerDayAllCubiclesReduced: function() {
              return this.costHeatingPerDayReduced * this.numberCubicles;
            },

            costWaterPerAnnumReduced: function() {
                const savings = showercost.getSaving(this.$data);
                return savings.costWaterPerAnnum;
            },

            costHeatingPerAnnumReduced: function() {
                const savings = showercost.getSaving(this.$data);
                return savings.costHeatingPerAnnum;
            },



            }, // end computed
            created() {


          },

          filters: {
            dollarAmount: function(val) {

              if(typeof(val) == 'string' && val.match(/[^\d.]+/)){
                return val;
              }

              let money = (val/100).toLocaleString('en-AU',{
                  style: 'currency',
                  currency: 'AUD'
              });

              if(parseInt(val/100) < 100) {
                  return money;
              }

              if(parseInt(val/100) < 1000) {
                  return money.replace(/\.\d{2}$/, '');
              }

              let wholeDollars = Math.round(val/100);
              let wholeDollarsStr = '';

              if(wholeDollars >= 1000) {
                wholeDollarsStr = `${wholeDollars}`;
                wholeDollarsStr = wholeDollarsStr.replace(/(\d{3})$/, ',$1');
              }

              if(wholeDollars >= 1000000) {
                wholeDollarsStr = `${wholeDollars}`;
                wholeDollarsStr = wholeDollarsStr.replace(/(\d{3})(\d{3})$/, ',$1,$2');
              }

              return `$${wholeDollarsStr }`;
            }
          },
         mounted() {

             const url = new URL(document.location.href);
             const searchParams = new URLSearchParams(url.search);
             let data = {};
             data = wpData;
console.log(wpData);
             if(searchParams.has("showerFlowRate")) {
                searchParams.forEach(function(value, key) {
                   data[key] = value;
                });
            } else {

            }

             showercost.setData(data);
             Object.assign(this.$data, data);
             this.showerCostData = data;
             this.showerFlowRate = data.showerFlowRate;
             this.waterCost = data.waterCost;
            let dynTextParsed = JSON.parse(data.dynamicText);
             this.dynamicText = dynTextParsed;

             setTimeout(() => {
                 this.cacheDynamicTextEls();
                 this.dynamicTextUpdate();
             }, 500);


          }

      }
</script>

<style scoped>

      .fade-enter-active, .fade-leave-active {
        transition: opacity 2s;
      }
      .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
      }

    .slider-box {
        @apply flex justify-end items-center
    }

    .slider-box-p {

        @apply flex-auto text-right
    }

    .slider-box-input {
      @apply flex-initial text-right;
    }

    .slider-box-input input {


        direction: rtl;

        @apply text-right text-sm pr-1 w-20

    }

    .range-slider {
        margin-right: .5em;
        flex: 1 0 25%;
    }



    .reduced {
      @apply line-through pr-1 text-gray-600;
    }

    .showerTimer-heading {

    }

    #dynamicText {
      text-align: left;
    }

</style>
